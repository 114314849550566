import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../config/axiosConfig';
import {
    Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Grid, Typography, Box, List, ListItem, ListItemText, ListItemIcon,
    useMediaQuery, useTheme, Drawer, IconButton, AppBar, Toolbar, Card,
    CardContent, CardActions, TextField, Divider, Snackbar, Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import CloseIcon from '@mui/icons-material/Close';
import BackspaceIcon from '@mui/icons-material/Backspace';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExitDialog from './ExitDialog';
import { useAuth } from '../context/AuthContext';

axios.defaults.baseURL = 'https://haruittl.asuscomm.com/api';
axios.defaults.withCredentials = true;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        '& > *': {
            color: theme.palette.primary.contrastText,
        },
    },
}));

const ParkingStatus = ({ enteredCars, remainingCars }) => {
    return (
        <Paper elevation={3} sx={{ p: 2, mb: 2, bgcolor: 'background.default' }}>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <DirectionsCarIcon sx={{ fontSize: 40, color: 'primary.main' }} />
                        <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                            {enteredCars}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <LocalParkingIcon sx={{ fontSize: 40, color: 'secondary.main' }} />
                        <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'secondary.main' }}>
                            {remainingCars}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};

const ParkingManagementMain = () => {
    const [selectedLocation, setSelectedLocation] = useState('SPC');
    const [carNumber, setCarNumber] = useState('0000');
    const [parkedCars, setParkedCars] = useState([]);
    const [enteredCars, setEnteredCars] = useState(0);
    const [remainingCars, setRemainingCars] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);
    const [keypadOpen, setKeypadOpen] = useState(false);
    const [exitDialogOpen, setExitDialogOpen] = useState(false);
    const [selectedCar, setSelectedCar] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const { user, validateToken, logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const validateTokenPeriodically = async () => {
          if (user && user.token) {
            try {
              await validateToken(user.token);
            } catch (error) {
              console.error('Token validation failed:', error);
              await logout();
              navigate('/login');
            }
          }
        };
    
        const intervalId = setInterval(validateTokenPeriodically, 15 * 60 * 1000); // 15분마다 실행
    
        return () => clearInterval(intervalId);
      }, [user, validateToken, logout, navigate]);

    useEffect(() => {
        if (!user) {
            navigate('/login');
        } else {
            fetchParkedCars();
        }
    }, [user, navigate]);

    useEffect(() => {
        const interceptor = axios.interceptors.request.use(
            config => {
                const token = localStorage.getItem('token');
                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                console.log('Request config:', config);  // 요청 설정 로깅
                return config;
            },
            error => {
                console.error('Request interceptor error:', error);  // 에러 로깅
                return Promise.reject(error);
            }
        );
    
        return () => {
            axios.interceptors.request.eject(interceptor);
        };
    }, []);

    const fetchParkedCars = async () => {
        try {
          const response = await axios.get('/parking/list');
          console.log('Parked cars response:', response.data);
          setParkedCars(response.data);
      
          const statusResponse = await axios.get('/parking/status');
          console.log('Parking status response:', statusResponse.data);
          setEnteredCars(statusResponse.data.occupiedSpaces);
          setRemainingCars(statusResponse.data.availableSpaces);
        } catch (error) {
          console.error('Fetch error:', error.response || error);
          if (error.response) {
            switch (error.response.status) {
              case 401:
                showSnackbar('인증이 만료되었습니다. 다시 로그인해주세요.', 'error');
                // logout();
                // navigate('/login');
                break;
              case 403:
                showSnackbar('접근 권한이 없습니다.', 'error');
                break;
              default:
                showSnackbar('주차 정보를 불러오는 중 오류가 발생했습니다.', 'error');
            }
          } else if (error.request) {
            showSnackbar('서버에 연결할 수 없습니다. 네트워크 연결을 확인해주세요.', 'error');
          } else {
            showSnackbar('요청 설정 중 오류가 발생했습니다.', 'error');
          }
        }
      };

    const handleCarEntry = async () => {
        try {
            const response = await axios.post('/parking/entry', {
                carNumber,
                locationName: selectedLocation
            });
            showSnackbar('차량이 성공적으로 입차되었습니다.', 'success');
            fetchParkedCars();
            return { success: true, message: '차량이 성공적으로 입차되었습니다.' };
        } catch (error) {
            if (error.response && error.response.status === 401) {
                showSnackbar('인증이 만료되었습니다. 다시 로그인해주세요.', 'error');
                logout();
                navigate('/login');
            } else {
                showSnackbar('입차 처리 중 오류가 발생했습니다.', 'error');
            }
            return { success: false, message: '입차 처리 중 오류가 발생했습니다.' };
        }
    };

    const handleCarExit = async () => {
        try {
            const response = await axios.post('/parking/exit', { carNumber });
            setSelectedCar(response.data);
            setExitDialogOpen(true);
            return { success: true, car: response.data };
        } catch (error) {
            if (error.response && error.response.status === 401) {
                showSnackbar('인증이 만료되었습니다. 다시 로그인해주세요.', 'error');
                logout();
                navigate('/login');
            } else {
                showSnackbar('출차 처리 중 오류가 발생했습니다.', 'error');
            }
            return { success: false, message: '출차 처리 중 오류가 발생했습니다.' };
        }
    };

    const confirmCarExit = async (updatedCar) => {
        try {
            await axios.post('/parking/confirm-exit', { carNumber: updatedCar.carNumber });
            showSnackbar('차량이 성공적으로 출차되었습니다.', 'success');
            fetchParkedCars();
            return { success: true, message: '차량이 성공적으로 출차되었습니다.' };
        } catch (error) {
            if (error.response && error.response.status === 401) {
                showSnackbar('인증이 만료되었습니다. 다시 로그인해주세요.', 'error');
                logout();
                navigate('/login');
            } else {
                showSnackbar('출차 확인 중 오류가 발생했습니다.', 'error');
            }
            return { success: false, message: '출차 확인 중 오류가 발생했습니다.' };
        }
    };

    const handleLogout = async () => {
        try {
            await axios.post('/auth/signout');
            logout();
            navigate('/');
            showSnackbar('로그아웃되었습니다.', 'success');
        } catch (error) {
            showSnackbar('로그아웃 중 오류가 발생했습니다.', 'error');
        }
    };

    const handleNumberInput = (value) => {
        setCarNumber(prev => {
            if (prev === '0000') {
                return value.padStart(4, '0');
            } else {
                const newNumber = (prev + value).slice(-4);
                return newNumber.padStart(4, '0');
            }
        });
    };

    const handleDelete = () => {
        setCarNumber(prev => ('0' + prev.slice(0, -1)).padStart(4, '0'));
    };

    const handleClear = () => {
        setCarNumber('0000');
    };

    const showSnackbar = (message, severity = 'info') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleCarEntryClick = () => {
        handleCarEntry().then(result => {
            if (result.success) {
                handleClear();
                if (isMobile) {
                    setKeypadOpen(false);
                }
            }
        });
    };

    const handleCarExitClick = () => {
        handleCarExit().then(result => {
            if (result.success) {
                handleClear();
                if (isMobile) {
                    setKeypadOpen(false);
                }
            }
        });
    };

    const handleExitConfirm = (updatedCar) => {
        confirmCarExit(updatedCar).then(() => {
            setExitDialogOpen(false);
        });
    };

    const handleButtonClick = (action, value) => {
        switch (action) {
            case 'clear':
                handleClear();
                break;
            case 'delete':
                handleDelete();
                break;
            case 'entry':
                handleCarEntryClick();
                break;
            case 'exit':
                handleCarExitClick();
                break;
            case 'number':
                handleNumberInput(value);
                break;
            case 'location':
                setSelectedLocation(value);
                break;
            default:
                break;
        }
    };

    const renderMenu = () => (
        <List>
            <ListItem button>
                <ListItemText primary="주차정산" />
            </ListItem>
            <ListItem button>
                <ListItemText primary="주차정책설정" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
                <ListItemIcon>
                    <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="로그아웃" />
            </ListItem>
            <ParkingStatus enteredCars={enteredCars} remainingCars={remainingCars} />
        </List>
    );

    const renderKeypad = () => (
        <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                p: 2,
                borderRadius: '4px 4px 0 0'
            }}>
                <Typography variant="h6">차량번호 입력</Typography>
                {isMobile && (
                    <IconButton onClick={() => setKeypadOpen(false)} sx={{ color: 'inherit' }}>
                        <CloseIcon />
                    </IconButton>
                )}
            </Box>
            <Box sx={{ p: 2, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <Button
                        variant={selectedLocation === 'SPC' ? 'contained' : 'outlined'}
                        onClick={() => setSelectedLocation('SPC')}
                        sx={{ mr: 1, flexGrow: 1 }}
                    >
                        SPC
                    </Button>
                    <Button
                        variant={selectedLocation === '공예관' ? 'contained' : 'outlined'}
                        onClick={() => setSelectedLocation('공예관')}
                        sx={{ flexGrow: 1 }}
                    >
                        공예관
                    </Button>
                </Box>
                <TextField
                    value={carNumber}
                    onChange={(e) => setCarNumber(e.target.value)}
                    inputProps={{
                        style: { fontSize: '2rem', textAlign: 'center' },
                        maxLength: 4
                    }}
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                />
                <Grid container spacing={1} sx={{ mb: 2 }}>
                    {[7, 8, 9, 4, 5, 6, 1, 2, 3].map((value) => (
                        <Grid item xs={4} key={value}>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={() => handleButtonClick('number', value.toString())}
                                sx={{ height: '3rem', fontSize: '1.5rem' }}
                            >
                                {value}
                            </Button>
                        </Grid>
                    ))}
                    <Grid item xs={4}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => handleButtonClick('clear')}
                            sx={{ height: '3rem' }}
                        >
                            <RestartAltIcon />
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => handleButtonClick('number', '0')}
                            sx={{ height: '3rem', fontSize: '1.5rem' }}
                        >
                            0
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => handleButtonClick('delete')}
                            sx={{ height: '3rem' }}
                        >
                            <BackspaceIcon />
                        </Button>
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleButtonClick('entry')}
                        sx={{ flexGrow: 1, mr: 1, height: '3rem' }}
                    >
                        입차
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleButtonClick('exit')}
                        sx={{ flexGrow: 1, height: '3rem' }}
                    >
                        출차
                    </Button>
                </Box>
            </Box>
        </Paper>
    );

    const renderCarList = () => {
        if (isMobile) {
            return (
                <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)', p: 2 }}>
                    {parkedCars.map((car, index) => (
                        <Card key={index} sx={{
                            mb: 2,
                            boxShadow: 3,
                            transition: 'all 0.3s',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: 6,
                            }
                        }}>
                            <CardContent>
                                <Typography variant="h6" color="primary">차량번호: {car.carNumber}</Typography>
                                <Typography variant="body1"><strong>이용매장:</strong> {car.locationName}</Typography>
                                <Typography variant="body1"><strong>입차시간:</strong> {car.entryTime}</Typography>
                                <Typography variant="body1"><strong>출차시간:</strong> {car.exitTime || '-'}</Typography>
                                <Typography variant="body1"><strong>주차시간:</strong> {car.duration || '-'}</Typography>
                                <Typography variant="body1"><strong>주차요금:</strong> {car.fee}</Typography>
                                <Typography variant="body1"><strong>할인요금:</strong> {car.discount}</Typography>
                                <Typography variant="h6" color="secondary"><strong>최종요금:</strong> {car.finalFee}</Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" variant="contained" color="primary" onClick={() => handlePrintReceipt(car)}>
                                    영수증 출력
                                </Button>
                            </CardActions>
                        </Card>
                    ))}
                </Box>
            );
        } else {
            return (
                <TableContainer component={Paper} sx={{
                    height: '100%',
                    overflowY: 'auto',
                    boxShadow: 3,
                }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold', bgcolor: 'primary.main', color: 'primary.contrastText' }}>No.</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', bgcolor: 'primary.main', color: 'primary.contrastText' }}>차량번호</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', bgcolor: 'primary.main', color: 'primary.contrastText' }}>이용매장</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', bgcolor: 'primary.main', color: 'primary.contrastText' }}>입차시간</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', bgcolor: 'primary.main', color: 'primary.contrastText' }}>출차시간</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', bgcolor: 'primary.main', color: 'primary.contrastText' }}>주차시간</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', bgcolor: 'primary.main', color: 'primary.contrastText' }}>주차요금</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', bgcolor: 'primary.main', color: 'primary.contrastText' }}>할인요금</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', bgcolor: 'primary.main', color: 'primary.contrastText' }}>최종요금</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', bgcolor: 'primary.main', color: 'primary.contrastText' }}>영수증</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {parkedCars.map((car, index) => (
                                <StyledTableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{car.carNumber}</TableCell>
                                    <TableCell>{car.locationName}</TableCell>
                                    <TableCell>{car.entryTime}</TableCell>
                                    <TableCell>{car.exitTime || '-'}</TableCell>
                                    <TableCell>{car.duration || '-'}</TableCell>
                                    <TableCell>{car.fee}</TableCell>
                                    <TableCell>{car.discount}</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'secondary.main' }}>{car.finalFee}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => handlePrintReceipt(car)}
                                            sx={{
                                                bgcolor: 'secondary.main',
                                                '&:hover': {
                                                    bgcolor: 'secondary.dark',
                                                }
                                            }}
                                        >
                                            출력
                                        </Button>
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }
    };

    const handlePrintReceipt = (car) => {
        // 영수증 출력 로직 구현
        console.log("영수증 출력:", car);
        showSnackbar("영수증 출력 기능은 아직 구현되지 않았습니다.", "info");
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            {isMobile && (
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={() => setMenuOpen(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            주차 관리
                        </Typography>
                        <IconButton
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="keypad"
                            onClick={() => setKeypadOpen(true)}
                        >
                            <KeyboardIcon />
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="logout"
                            onClick={handleLogout}
                        >
                            <ExitToAppIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            )}
            <Grid container sx={{ height: isMobile ? 'calc(100vh - 56px)' : '100vh' }}>
                {!isMobile && (
                    <Grid item xs={2} sx={{ borderRight: 1, borderColor: 'divider' }}>
                        {renderMenu()}
                    </Grid>
                )}
                <Grid item xs={isMobile ? 12 : 10}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={9}>
                            {renderCarList()}
                        </Grid>
                        {!isMobile && (
                            <Grid item xs={3}>
                                {renderKeypad()}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Drawer
                anchor="left"
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
            >
                {renderMenu()}
            </Drawer>
            <Drawer
                anchor="right"
                open={keypadOpen}
                onClose={() => setKeypadOpen(false)}
            >
                {renderKeypad()}
            </Drawer>
            <ExitDialog
                open={exitDialogOpen}
                onClose={() => setExitDialogOpen(false)}
                car={selectedCar}
                onConfirm={handleExitConfirm}
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ParkingManagementMain;