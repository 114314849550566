import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    Box,
    ButtonGroup
} from '@mui/material';
import {
    calculateDiscount,
    calculateFinalFee
} from '../utils/parkingUtils';

const ExitDialog = ({ open, onClose, car, onConfirm }) => {
    const [ticketCount, setTicketCount] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [finalFee, setFinalFee] = useState(car?.fee || 0);
    const [isFullDiscount, setIsFullDiscount] = useState(false);

    useEffect(() => {
        if (car) {
            const newDiscount = isFullDiscount ? car.fee : calculateDiscount(car.locationName, ticketCount);
            setDiscount(newDiscount);
            setFinalFee(calculateFinalFee(car.fee, newDiscount));
        }
    }, [car, ticketCount, isFullDiscount]);

    const handleConfirm = () => {
        onConfirm({
            ...car,
            ticketCount: isFullDiscount ? 0 : ticketCount,
            discount,
            finalFee
        });
    };

    const handleTicketChange = (value) => {
        setIsFullDiscount(false);
        setTicketCount(Math.max(0, value));
    };

    const renderSPCTicketInput = () => (
        <Box display="flex" alignItems="center" my={2}>
            <ButtonGroup>
                <Button onClick={() => handleTicketChange(ticketCount - 1)}>-</Button>
                <TextField
                    value={ticketCount}
                    onChange={(e) => handleTicketChange(parseInt(e.target.value) || 0)}
                    inputProps={{ style: { textAlign: 'center', width: '50px' } }}
                />
                <Button onClick={() => handleTicketChange(ticketCount + 1)}>+</Button>
            </ButtonGroup>
            <Button 
                variant={isFullDiscount ? "contained" : "outlined"} 
                onClick={() => setIsFullDiscount(!isFullDiscount)}
                sx={{ ml: 2 }}
            >
                전액 할인
            </Button>
        </Box>
    );

    const renderGongYeGwanTicketInput = () => (
        <Box my={2}>
            <ButtonGroup fullWidth>
                <Button 
                    variant={ticketCount === 1 && !isFullDiscount ? "contained" : "outlined"} 
                    onClick={() => handleTicketChange(1)}
                >
                    1시간30분
                </Button>
                <Button 
                    variant={ticketCount === 2 && !isFullDiscount ? "contained" : "outlined"}
                    onClick={() => handleTicketChange(2)}
                >
                    2시간
                </Button>
                <Button 
                    variant={ticketCount === 3 && !isFullDiscount ? "contained" : "outlined"}
                    onClick={() => handleTicketChange(3)}
                >
                    2시간30분
                </Button>
                <Button 
                    variant={ticketCount === 4 && !isFullDiscount ? "contained" : "outlined"}
                    onClick={() => handleTicketChange(4)}
                >
                    3시간
                </Button>
            </ButtonGroup>
            <Button 
                fullWidth 
                variant={isFullDiscount ? "contained" : "outlined"} 
                onClick={() => setIsFullDiscount(!isFullDiscount)}
                sx={{ mt: 1 }}
            >
                전액 할인
            </Button>
        </Box>
    );

    if (!car) return null;

    return (
        <Dialog open={open} onClose={() => { onClose(); setTicketCount(0); setIsFullDiscount(false); }} maxWidth="sm" fullWidth>
            <DialogTitle>출차 정보</DialogTitle>
            <DialogContent>
                <Typography><strong>차량번호:</strong> {car.carNumber}</Typography>
                <Typography><strong>이용매장:</strong> {car.locationName}</Typography>
                <Typography><strong>입차시간:</strong> {car.entryTime}</Typography>
                <Typography><strong>출차시간:</strong> {car.exitTime}</Typography>
                <Typography><strong>주차시간:</strong> {car.duration}</Typography>
                <Typography><strong>주차요금:</strong> {car.fee}원</Typography>
                
                {car.locationName === 'SPC' ? renderSPCTicketInput() : renderGongYeGwanTicketInput()}

                <Typography><strong>할인금액:</strong> {discount}원</Typography>
                <Typography variant="h6"><strong>최종요금:</strong> {finalFee}원</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { onClose(); setTicketCount(0); setIsFullDiscount(false); }} color="primary">
                    취소
                </Button>
                <Button onClick={() => { handleConfirm(); setTicketCount(0); setIsFullDiscount(false); }} color="primary" variant="contained">
                    출차
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExitDialog;