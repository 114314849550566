import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import axios from '../config/axiosConfig';
import { useAuth } from '../context/AuthContext';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await axios.post('/auth/signin', 
        { username, password },
        { 
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      
      console.log('Response:', response);  // 전체 응답 로깅
      
      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        auth.login(response.data.token);
        navigate('/home');
      } else {
        console.error('Unexpected response structure:', response.data);
        setError('로그인 응답의 구조가 예상과 다릅니다.');
      }
    } catch (err) {
      console.error('Login error:', err);
      if (err.response) {
        console.error('Error response:', err.response);
        setError(err.response.data.message || '로그인에 실패했습니다. 사용자 이름과 비밀번호를 확인해주세요.');
      } else if (err.request) {
        console.error('Error request:', err.request);
        setError('서버에 연결할 수 없습니다. 네트워크 연결을 확인해주세요.');
      } else {
        console.error('Error message:', err.message);
        setError('로그인 요청 중 오류가 발생했습니다: ' + err.message);
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          로그인
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="사용자 이름"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="비밀번호"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            로그인
          </Button>
          <Link to="/signup" style={{ textDecoration: 'none' }}>
            <Typography variant="body2" color="primary" align="center">
              계정이 없으신가요? 회원가입
            </Typography>
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
