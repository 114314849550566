import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Container } from '@mui/material';

const HomePage = () => {
  return (
    <Container maxWidth={false}>
      <Box sx={{ display: 'flex', height: '100vh' }}>
        <Outlet />
      </Box>
    </Container>
  );
};

export default HomePage;