import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from '../config/axiosConfig';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          await validateToken(token);
        } catch (error) {
          console.error('Initial token validation failed:', error);
        }
      }
      setLoading(false);
    };

    initializeAuth();
  }, []);

  const validateToken = async (token) => {
    try {
      const response = await axios.post('/auth/validate-token', { token });
      console.log('Token validation response:', response.data);
      if (response.data.valid) {
        setUser({ token });
      } else {
        await logout();
      }
    } catch (error) {
      console.error('Token validation error:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      await logout();
    }
  };

  const login = (token) => {
    localStorage.setItem('token', token);
    setUser({ token });
  };

  const logout = async () => {
    try {
      await axios.post('/auth/signout');
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      localStorage.removeItem('token');
      setUser(null);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ user, login, logout, validateToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};