import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://haruittl.asuscomm.com/api',
    withCredentials: true
});

instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        console.log('Request config:', config);
        return config;
    },
    error => {
        console.error('Request interceptor error:', error);
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            console.log('Unauthorized, logging out...');
            console.log(localStorage.getItem('token'));
            localStorage.removeItem('token');
            // 여기에 로그아웃 및 로그인 페이지로 리다이렉트 로직을 추가할 수 있습니다.
            //   window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default instance;