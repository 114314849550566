import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles'; // 예시: MUI를 사용하는 경우
import CssBaseline from '@mui/material/CssBaseline'; // 예시: MUI를 사용하는 경우
import App from './App'; // App 컴포넌트 import
import theme from './theme'; // 테마 import

const container = document.getElementById('root');
const root = createRoot(container); // createRoot 사용

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
