import React from 'react';
import { Box, Typography, Button, Container, Paper } from '@mui/material';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#f0f0f0', // 배경색 설정
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '50px 20px', // 상하 50px, 좌우 20px의 padding
        textAlign: 'center',
      }}
    >
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ p: 4, textAlign: 'center', borderRadius: 4, backgroundColor: 'white' }}>
          <Typography variant="h3" component="h1" gutterBottom sx={{ color: '#00204a', fontWeight: 'bold' }}>
            주차 관리 시스템
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#00204a', mb: 4 }}>
            효율적인 주차 관리를 위한 솔루션입니다. 쉽고 빠른 입출차 관리, 요금 계산, 통계 분석 등 다양한 기능을 제공합니다.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button variant="contained" color="primary" component={Link} to="/login" sx={{ mr: 2 }}>
              로그인
            </Button>
            <Button variant="outlined" color="primary" component={Link} to="/signup">
              회원가입
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default LandingPage;
