export const calculateParkingDurationInMinutes = (entryTime, exitTime) => {
    if (!exitTime) return 0;
    const entry = new Date(entryTime);
    const exit = new Date(exitTime);
    return Math.ceil((exit - entry) / (1000 * 60));
};

export const formatParkingDuration = (durationInMinutes) => {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return `${hours}시간 ${minutes}분`;
};

export const calculateFee = (location, durationInMinutes) => {
    let fee = 0;
    if (location === 'SPC') {
        if (durationInMinutes <= 120) { // 2시간 이내
            fee = 5000;
        } else {
            const extraHours = Math.ceil((durationInMinutes - 120) / 30);
            fee = 5000 + extraHours * 3000; // 30분마다 3000원
        }
    } else if (location === '공예관') {
        if (durationInMinutes <= 90) { // 1시간 30분 이내
            fee = 6000; // 무료
        } else {
            if (durationInMinutes > 90) { // 1시간 30분 초과
                const extraHours = Math.ceil((durationInMinutes - 90) / 30);
                fee = extraHours * 2000; // 30분마다 2000원
            }
        }
    }
    return fee;
};

export const calculateDiscount = (location, ticketCount) => {
    let discount = 0;
    if (location === 'SPC') {
        if (ticketCount >= 1) discount += 3000;
        if (ticketCount >= 2) discount += 2000;
        if (ticketCount >= 3) discount += 6000;
        if (ticketCount >= 4) discount += 6000 * (ticketCount - 3);
    } else if (location === '공예관') {
        if (ticketCount >= 1) discount += 6000;
        if (ticketCount >= 2) discount += 2000;
        if (ticketCount >= 3) discount += 2000 * (ticketCount - 2);
    }
    return discount;
};

export const calculateFinalFee = (fee, discount) => {
    return Math.max(0, fee - discount);
};

export const isValidCarNumber = (number) => {
    return /^(?!00)\d{4}$/.test(number);
};